<template>
  <div class="chats">
    <div
      v-for="(msgGrp, index) in chatData"
      :key="msgGrp.from.id + String(index)"
      class="chat"
      :class="{ 'chat-left': msgGrp.from.id != userInfo.id, 'chat-right': msgGrp.from.id == userInfo.id }"
    >
      <div
        v-if="isSameDate(msgGrp, chatData[index - 1]) || index == 0"
        class="text-center datetime-splitter"
      >
        {{ formatDate(msgGrp.datetime, msgGrp.time) }}
      </div>
      <div class="chat-avatar">
        <b-avatar
          size="36"
          class="avatar-border-2 box-shadow-1"
          variant="transparent"
          :src="$helpers.imageHelper(msgGrp.from.avatar)"
        />
      </div>
      <div class="chat-body relative">
        <div class="chat-content">
          <p v-if="msgGrp.message">
            {{ msgGrp.picture ? 'Sent an image' : msgGrp.message }}
          </p>
          <img
            v-if="msgGrp.picture"
            :src="$helpers.imageHelper(msgGrp.picture)"
            class="chat-content-message-image"
            @click="enlarge($event)"
            @error="$helpers.imageError"
          >
        </div>
        <p class="mb-0 date-format-chats">
          {{ formatTime(msgGrp.datetime, msgGrp.time) }}
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import { BAvatar } from 'bootstrap-vue'
import moment from 'moment'
import eventBus from '@/libs/event-bus'

export default {
  components: {
    BAvatar,
  },
  props: {
    chatData: {
      type: [Object, Array],
      required: true,
    },
    profileUserAvatar: {
      type: String,
      default: '',
    },
  },
  computed: {
    userInfo() {
      return window.SuperApp.getters.userInfo()
    },
  },
  methods: {
    enlarge($event) {
      eventBus.$emit('show-enlarged', $event.target.src)
    },
    formatTime(date, time) {
      return date ? moment(date, 'DD-MM-YYYY HH:mm').format('HH:mm A') : moment(time).format('HH:mm A')
    },
    formatDate(date, time) {
      return date ? moment(date, 'DD-MM-YYYY').format('YYYY-MM-DD') : moment(time).format('YYYY-MM-DD')
    },
    isSameDate(current, prev) {
      if (!prev) {
        return false
      }
      if (this.formatDate(prev.datetime, prev.time) !== this.formatDate(current.datetime, current.time)) {
        return true
      }
      return false
    },
  },
}
</script>

<style scoped>
.chat-content-message-image {
  width: 200px;
  height: 200px;
  object-fit: cover;
}

.date-format-chats {
  position: absolute;
  bottom: -5px;
  width: 100%;
}

.chat-left .date-format-chats {
  left: 12px;
}

.chat-right .date-format-chats {
  right: 12px;
  text-align: right;
}

.chat-app-window .chats .chat-body .chat-content {
  margin-bottom: 14px;
}
</style>
